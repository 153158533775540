import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Loader } from 'components/loader';
import usePageTracking from 'hooks/usePageTracking';

const Careers = lazy(() => import('pages/Careers'));
const CookiePolicy = lazy(() => import('pages/CookiePolicy'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Home = lazy(() => import('pages/Home'));
const ItineraryBuilder = lazy(() => import('pages/ItineraryBuilder'));
const ItineraryBuilderSample = lazy(() =>
  import('pages/ItineraryBuilderSample')
);
const Login = lazy(() => import('pages/Login'));
const NotFound = lazy(() => import('pages/NotFound'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const PreRegistration = lazy(() => import('pages/PreRegistration'));
const PreRegistrationConfirmation = lazy(() =>
  import('pages/PreRegistrationConfirmation')
);
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Registration = lazy(() => import('pages/Registration'));
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'));
const ThankYou = lazy(() => import('pages/ThankYou'));

const RoutesList = () => {
  usePageTracking();
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/careers" component={Careers} />
      <Route exact path="/join" component={PreRegistration} />
      <Route
        exact
        path="/preregistration-confirmation"
        component={PreRegistrationConfirmation}
      />
      <Route exact path="/thank-you" component={ThankYou} />
      <Route exact path="/register" component={Registration} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/itinerary-builder" component={ItineraryBuilder} />
      <Route
        exact
        path="/itinerary-builder/sample"
        component={ItineraryBuilderSample}
      />
      <Route exact path="/cookie-policy" component={CookiePolicy} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <RoutesList />
      </BrowserRouter>
    </Suspense>
  );
};

export default Routes;
