const Loader = () => {
  return (
    <div className="bg-cta-newsletter-background bg-gray-100 flex flex-col h-screen items-center justify-center w-screen">
      <div className="flex items-center justify-center">
        <span className="animate-ping absolute bg-trippr-primary-600 h-20 p-3 rounded-full w-20"></span>
        <div className="bg-trippr-primary-600 h-32 p-3 rounded-full w-32 z-50">
          <img
            alt="Trippr Icon"
            src="/assets/logo/trippr-plane.png"
            title="Taking you to your destination"
          />
        </div>
      </div>
      <p className="mt-10">Taking you to your destination</p>
    </div>
  );
};

const Spinner = () => {
  return (
    <div className="flex flex-row h-14 items-center justify-start mt-3 px-5 w-full md:justify-center md:px-0">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export { Loader, Spinner };
